import React, { useEffect } from 'react'
import { Script } from 'gatsby'

const SuccessMessage = () => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', 'AW-16895233978')
    }
  }, [])

  return (
    <>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-16895233978"
        strategy="idle"
      />
      <div className="text-sm text-center text-gray-800">
        <h2 className="text-2xl font-bold text-black"> {language == 'en' ? 'Thank you!' : 'Grazie'}</h2>
        <p>
          {language == 'en' ? <>
            Your message was sent to us.
            <br />
            We'll get back to you!
          </> : <>
            Il tuo messaggio ci è stato inviato..
            <br />
            Ti risponderemo presto!!
          </>}
        </p>
        <img
          className="w-32 h-32 mx-auto my-8 bg-gray-800 rounded-full"
          src="/profile.png"
          alt="Team member profile"
        />
        <p className="text-base md:text-lg">~ Team Soul Valley</p>
      </div>
    </>
  )
}

export default SuccessMessage
